import axios from 'axios'
import store from '@/store'
import { sendToApp } from '@/helpers'

const OXYZEN_URL = process.env.VUE_APP_OXYGEN_DOMAIN

const { getters, commit } = store

const toggleBodyScroll = (locked: boolean) => {
  document.body.classList.toggle('modal-open', locked)
}

axios.defaults.paramsSerializer = function (paramObj) {
  const params = new URLSearchParams()
  for (const key in paramObj) {
    if (Array.isArray(paramObj[key])) {
      for (let i = 0; i < paramObj[key].length; i++) {
        params.append(key, paramObj[key][i])
      }
    } else {
      params.append(key, paramObj[key])
    }
  }

  return params.toString()
}

axios.interceptors.request.use(
  config => {
    const queryStrings = new URL(window.location.href).searchParams
    const hasOxygenPrd = (queryStrings.get('oxygen_prd') || '') === process.env.VUE_APP_OXYGEN_QUERY
    commit('$SET_STATE', { path: 'isHttpRequesting', value: true })
    toggleBodyScroll(true)
    const ENGLISH = 2
    const { jwt: token, uuid } = sessionStorage
    config.headers.post['Content-Type'] = 'application/json'
    config.baseURL = hasOxygenPrd ? OXYZEN_URL : process.env.VUE_APP_API
    config.headers.common.platform = '1'
    config.headers.common.locale = `${getters['country/localeId'] || ENGLISH}`
    config.headers.common.api_version = uuid ? '1.95' : '1.94'
    token && (config.headers.common.Authorization = `Bearer ${token}`)
    uuid && (config.headers.common.device_trace_id = uuid)
    return config
  },
  error => Promise.reject(error)
)

const showErrorMessage = (response: any, isEnteredInvalidCouponCode: boolean) => {
  const isRequireErrorMessage = !isEnteredInvalidCouponCode
  isRequireErrorMessage && commit('$SET_STATE', { path: 'alert.errorMessage', value: response.data.message }, { root: true })
}

axios.interceptors.response.use(
  response => {
    commit('$SET_STATE', { path: 'isHttpRequesting', value: false })
    toggleBodyScroll(false)
    const [DEVICE_ERROR, EXPIRED_TOKEN, DUPLICATED_TOKEN] = [40, 42, 47]
    const isInvalidToken = [DEVICE_ERROR, EXPIRED_TOKEN, DUPLICATED_TOKEN].includes(response.data.code)
    if (isInvalidToken) {
      const curUrl = new URL(window.location.href)
      const isNoRefresh = curUrl.searchParams.get('session') === 'no-refresh'
      if (!isNoRefresh) {
        sessionStorage.removeItem('jwt')
        sessionStorage.removeItem('uuid')
      }
      sendToApp('logout', { message: response.data.message })
    }
    if (response.data.code !== 200) {
      const { url, data } = response.config
      const isEnteredInvalidCouponCode: boolean = url === '/api/person/coupon' &&
        (data && JSON.parse(data).code && !JSON.parse(data).coupon_id)
      isEnteredInvalidCouponCode && commit('$SET_STATE', { path: 'util.inputMessage', value: response.data.message }, { root: true })

      showErrorMessage(response, isEnteredInvalidCouponCode)
      throw response.data
    }
    return response.data
  },
  error => {
    commit('$SET_STATE', { path: 'isHttpRequesting', value: false })
    toggleBodyScroll(false)
    return error
  }
)
