import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import store from '@/store'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import { sendToApp } from '@/helpers'

import type { ICountry, ILocale } from '@/types'

Vue.use(VueRouter)

const vm: any = new Vue()

const loginOnly = (next: any) => {
  if (store.getters['auth/$getPid']) return next()
  sendToApp('logout', { message: vm.$i18n('Global.paragraph.errormessage.abnomal').value })
}

const verificationOnly = (next: any) => {
  if (store.getters['auth/isVerifyCompleteStatus']) return next()
  if (store.getters['auth/$getPid']) return sendToApp('verifications')
  sendToApp('logout', { message: vm.$i18n('Global.paragraph.errormessage.abnomal').value })
}

const routes: Array<RouteConfig> = [
  {
    path: '/:lang/benefits',
    name: 'Benefits',
    component: () => import('../views/BenefitMain.vue'),
    beforeEnter: (to, from, next) => {
      loginOnly(next)
    }
  },
  {
    path: '/:lang/login-guide',
    name: 'LoginGuide',
    component: () => import('../views/LoginGuide.vue')
  },
  {
    path: '/:lang/my-coupon',
    name: 'MyCoupon',
    component: () => import('../views/MyCoupon.vue'),
    beforeEnter: (to, from, next) => {
      loginOnly(next)
    }
  },
  {
    path: '/:lang/coupon-book',
    name: 'CouponBook',
    component: () => import('../views/CouponBook.vue'),
    beforeEnter: (to, from, next) => {
      verificationOnly(next)
    }
  },
  {
    path: '/:lang/my-grade',
    name: 'MyGrade',
    component: () => import('../views/MyGrade.vue'),
    beforeEnter: (to, from, next) => {
      loginOnly(next)
    }
  },
  {
    path: '/:lang/invite-friend',
    name: 'InviteFriend',
    component: () => import('../views/InviteFriendDetails.vue'),
    beforeEnter: (to, from, next) => {
      loginOnly(next)
    }
  },
  {
    path: '/:lang/*',
    redirect: '/:lang/login-guide'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

const setPerson = async (jwt: string) => {
  if (store.getters['auth/$getPid']) return
  const token = sessionStorage.jwt || jwt
  try {
    const [{ data: person = {} }, { data: grade }, { data: verifiedCountries }] = await Promise.all([
      axios.post('/api/person/search', { id: (jwtDecode as any)(token).user_id }),
      axios.post('/api/grade', { id: (jwtDecode as any)(token).user_id }),
      axios.get('/api/person/verification', { params: { pid: (jwtDecode as any)(token).user_id, source_countries: (store.getters['country/$sourceCountries'] as ICountry[]).map(({ id }) => id) } })
    ])

    store.commit('$SET_STATE', { path: 'auth.person', value: Object.assign({}, person, grade) })
    store.commit('$SET_STATE', { path: 'auth.verifiedSourceCountries', value: verifiedCountries.verifyied_source_countries })
  } catch (error) {
    console.log(error)
  }
}

export const loginByToken = async ({ query }: { [key: string]: any }) => {
  const { jwt, refresh_jwt: refreshJwt, uuid: mobileUuid } = query
  const uuid = sessionStorage.uuid || mobileUuid || ''
  const hasToken = jwt && refreshJwt
  const requireSetToken = hasToken && !sessionStorage.jwt
  if (requireSetToken) {
    sessionStorage.jwt = jwt
    sessionStorage.refreshJwt = refreshJwt
    sessionStorage.uuid = uuid
  }
  hasToken && await setPerson(jwt)
}

const reqDelay = (name: string, sec: number) => {
  !sessionStorage[name] && (sessionStorage[name] = new Date().toJSON())
  const verifyTime = Date.parse(sessionStorage[name])
  const currentTime = Date.parse(new Date().toJSON())
  return currentTime > verifyTime + (1000 * sec)
}

const refreshJwt = async () => {
  const invalidState = !sessionStorage.getItem('refreshJwt') ||
    !store.getters['auth/$getPid'] ||
    !reqDelay('refreshDelay', 60)
  if (invalidState) return
  sessionStorage.refreshDelay = new Date().toJSON()
  await store.dispatch('auth/JWT_REFRESH')
}

const setCountries = async () => {
  await store.dispatch('country/INIT_COUNTRIES')
}

const isValidLanguage = (lang: string) => {
  const locales = (store.state.country.locales as ILocale[]).map(({ locale }) => locale.slice(0, 2))
  return locales.includes(lang)
}

const setDefaultLanguage = (lang: string) => {
  if (!isValidLanguage(lang) || lang === store.state.lang) return
  store.commit('$SET_STATE', { path: 'lang', value: lang })
}

router.beforeEach(async (to, from, next) => {
  const { lang: paramLanguage } = to.params
  const { language: BrowserLanguage } = navigator
  const { path, query } = to
  setDefaultLanguage(paramLanguage)
  await setCountries()
  await loginByToken(to)
  query.session === 'no-refresh' && await refreshJwt()
  !store.getters.hasBaseData &&
    store.commit('$SET_STATE', { path: 'hasBaseData', value: true })
  if (!paramLanguage || !isValidLanguage(paramLanguage)) {
    const localeIso = BrowserLanguage.slice(0, 2)
    const locale = isValidLanguage(localeIso)
      ? localeIso
      : 'en'
    return next({ path: `/${locale}${path}`, query })
  }

  const isUnsynkToken = query.jwt !== sessionStorage.jwt
  if (isUnsynkToken) {
    const queryByRefreshToken = Object.assign({}, query, { jwt: sessionStorage.jwt, refresh_jwt: sessionStorage.refreshJwt, uuid: sessionStorage.uuid })
    return next({ path, query: queryByRefreshToken })
  }
  next()
})

export default router
